import { SupportedLocales } from "@xenia-libs/resident-app-i18n/supported-locales";
import { ErrorCode } from "./error-code";

export enum AvailableParams {
  LOCALE = "locale",
  CONFIG_ID = "configId",
  BOOKING_ID = "bookingId",
  ARTICLE_ID = "articleId",
}

/**
 * Params that are available in their specific route
 */
export type Params = {
  [AvailableParams.BOOKING_ID]: string;
  [AvailableParams.ARTICLE_ID]: string;
};

/**
 * Route params that are available on every route
 */
export type GlobalParams = {
  [AvailableParams.LOCALE]: SupportedLocales;
  [AvailableParams.CONFIG_ID]: string;
};

export type AllParams = GlobalParams & Params;

/**
 * Helper type to pluck the params needed for a route which then infers the type of the params
 * so we don't have to repeat the type in all of the page functions
 */
export type WithGlobalParams<Keys extends keyof Params = never> = {
  params: GlobalParams & Pick<Params, Keys>;
};

export enum AvailableSearchParams {
  CONFIRMATION_CODE = "confirmationCode",
  ERROR_CODE = "errorCode",
  QUERY = "q",
  FROM_RESIDENT_APP = "b",
}

export enum TruthySearchParam {
  TRUE = "1",
  FALSE = "0",
}

type SearchParam<T> = T | undefined | null;

export type SearchParams = {
  [AvailableSearchParams.CONFIRMATION_CODE]: SearchParam<string>;
  [AvailableSearchParams.ERROR_CODE]: SearchParam<ErrorCode>;
  [AvailableSearchParams.QUERY]: SearchParam<string>;
  [AvailableSearchParams.FROM_RESIDENT_APP]: SearchParam<TruthySearchParam>;
};

export type WithSearchParams<Keys extends keyof SearchParams = never> = {
  searchParams: Pick<SearchParams, Keys>;
};
