"use client";
import { FadeInTypography } from "../fade-in-typography";
import { ChooseLanguageInputs } from "./components/choose-language-inputs";
import { ChooseLanguageProps } from "./types/choose-language-props";
import { Box } from "@lavanda-uk/xenia-ui";
import mapValues from "lodash/mapValues";

export function ChooseLanguage({
  onSubmit,
  titles,
  defaultLocale,
  messages,
  options,
  onLanguageSelect,
}: ChooseLanguageProps) {
  return (
    <Box
      component="form"
      action={onSubmit}
      display="flex"
      flexDirection="column"
      gap={4}
      justifyContent="space-between"
      height="100%"
      maxWidth="400px"
      mx="auto"
      sx={(theme) => ({
        [theme.breakpoints.up("mobile")]: {
          justifyContent: "initial",
        },
      })}
    >
      <FadeInTypography
        sentences={titles}
        transitionDelay={5000}
        animateOnMount={titles.length > 1}
        typographyProps={{
          component: "h1",
          noWrap: true,
          lineHeight: 1.4,
        }}
      />

      <ChooseLanguageInputs
        options={options}
        messages={mapValues(
          messages,
          (localeMessages) => localeMessages.features.choose_language,
        )}
        defaultLocale={defaultLocale}
        onLanguageSelect={onLanguageSelect}
      />
    </Box>
  );
}
