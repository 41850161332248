import { UrlObject } from "url";

export type FormatUrlReturn = {
  pathname: string | undefined;
  search: string | undefined;
  hash: string | undefined;
};

export function formatUrl(href: UrlObject | string): FormatUrlReturn {
  if (typeof href === "object") {
    return {
      pathname: href.pathname || undefined,
      search: href.search || undefined,
      hash: href.hash || undefined,
    };
  }

  const [pathname, ...rest] = href.split("?");
  const [search, hash] = rest.join("?").split("#");

  return {
    pathname: pathname || undefined,
    search: search || undefined,
    hash: hash || undefined,
  };
}
