"use client";
import { useEffect, useState } from "react";
import { motion, AnimatePresence, useReducedMotion } from "framer-motion";
import {
  Typography,
  TypographyProps,
  visuallyHidden,
} from "@lavanda-uk/xenia-ui";

export type FadeInTypographyProps = {
  /**
   * The time between displaying each piece of text in milliseconds
   * @default 3000
   */
  transitionDelay?: number;
  /**
   * The time between displaying each letter in milliseconds
   * @default 50
   */
  lettersStagger?: number;
  /**
   * The text to cycle through
   */
  sentences: string[];
  /**
   * Props to pass to the `Typography` component
   */
  typographyProps?: TypographyProps;
  /**
   * Whether to animate the text when the component is first mounted
   */
  animateOnMount?: boolean;
};

const letterVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 },
};

export function FadeInTypography({
  transitionDelay = 3000,
  sentences,
  typographyProps,
  lettersStagger = 50,
  animateOnMount = false,
}: FadeInTypographyProps) {
  const [index, setIndex] = useState(0);
  const prefersReducedMotion = useReducedMotion();

  useEffect(() => {
    // Switch text after a delay
    const timeout = setTimeout(() => {
      setIndex((current) => (current + 1) % sentences.length);
    }, transitionDelay);

    return () => clearTimeout(timeout);
  }, [index, sentences, transitionDelay]);

  const sentence = sentences[index];

  if (!sentence) {
    return null;
  }

  const sentenceVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { staggerChildren: lettersStagger / 1000 },
    },
    exit: { opacity: 0 },
  };

  return (
    <>
      <AnimatePresence initial={animateOnMount} mode="wait">
        <motion.div
          key={index}
          variants={sentenceVariants}
          {...(prefersReducedMotion
            ? { initial: "visible" }
            : {
                initial: animateOnMount ? "hidden" : "visible",
                animate: "visible",
                exit: "exit",
              })}
        >
          <Typography aria-hidden variant="h1" {...typographyProps}>
            {sentence.split("").map((char, i) => (
              <motion.span aria-hidden key={i} variants={letterVariants}>
                {char}
              </motion.span>
            ))}
          </Typography>
        </motion.div>
      </AnimatePresence>

      <Typography style={visuallyHidden}>{sentence}</Typography>
    </>
  );
}
