import { UrlObject } from "url";
import { formatUrl } from "../format-url";
import { AvailableSearchParams, TruthySearchParam } from "../types";

export function getFromResidentAppHref(href: string | UrlObject) {
  const { pathname, search, hash } = formatUrl(href);

  const searchParams = new URLSearchParams(search);
  searchParams.set(
    AvailableSearchParams.FROM_RESIDENT_APP,
    TruthySearchParam.TRUE,
  );

  const hashValue = hash ? `#${hash}` : "";

  return `${pathname}?${searchParams.toString()}${hashValue}`;
}
