"use client";

import { useRouter as useNextRouter } from "next/navigation";
import { useCallback } from "react";
import { getFromResidentAppHref } from "@xenia-libs/resident-app-utils/get-from-resident-app-href";

type NavigateOptions = NonNullable<
  Parameters<ReturnType<typeof useNextRouter>["push"]>[1]
>;

type Navigate = (
  url: string,
  options?: NavigateOptions & { disabledFromResidentApp?: boolean },
) => void;

type Router = Omit<ReturnType<typeof useNextRouter>, "push" | "replace"> & {
  push: Navigate;
  replace: Navigate;
};

export const useRouter = (): Router => {
  const router = useNextRouter();

  const handleNavigate = useCallback(
    (callback: "push" | "replace"): Navigate => {
      return (url, options) => {
        return router[callback](
          options?.disabledFromResidentApp ? url : getFromResidentAppHref(url),
          options,
        );
      };
    },
    [router],
  );

  const push = useCallback<Navigate>(handleNavigate("push"), [handleNavigate]);

  const replace = useCallback<Navigate>(handleNavigate("replace"), [
    handleNavigate,
  ]);

  return {
    ...router,
    push,
    replace,
  };
};
